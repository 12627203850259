@import '@/styles/utils/functions/rem-calc';
@import '@/styles/utils/mixins/breakpoints';

.mainContainer {
  background-color: var(--pbsk-color-pbsblue);
  font-family: var(--pbsk-font-family-sans-bold);
  color: #fff;
  padding: rem-calc(24) var(--pbsk-margin);
  justify-content: center;
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  @include breakpoint(sm) {
    flex-direction: row;
    gap: rem-calc(20) 0;
    justify-content: space-between;
    justify-items: left;
  }

  @include breakpoint(md) {
    gap: rem-calc(24) 0;
  }

  @include breakpoint(lg) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: rem-calc(36);
    padding: rem-calc(36) rem-calc(16);
  }
}

.links {
  display: grid;
  grid-template-columns: 1fr;
  gap: rem-calc(16);

  text-align: center;
  color: var(--pbsk-color-logo-green);
  font-size: rem-calc(18);
  line-height: rem-calc(18);
  margin-bottom: rem-calc(36);

  a:hover {
    text-decoration: underline;
  }

  @include breakpoint(sm) {
    text-align: left;
    margin-bottom: 0;
  }

  @include breakpoint(lg) {
    grid-template-columns: none;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    gap: rem-calc(32);
    text-align: center;
    font-size: rem-calc(22);
  }
}

.stationSettingsWrapper {
  grid-column: 1 / -1;
  position: initial;

  @include breakpoint(sm) {
    position: relative;
  }
}

.logoWrapper {
  display: grid;
  gap: rem-calc(20);
  place-items: center center;
  align-content: center;
  margin-bottom: rem-calc(22);

  p {
    display: block;
    margin: 0;
    font-size: rem-calc(16);
    line-height: 1.25;
    text-transform: uppercase;
  }

  .stationLogo {
    display: block;
    margin: 0;

    img {
      display: block;
      margin: 0;
      max-width: calc(
        min( rem-calc(300), 100vw ) - (var(--pbsk-margin) * 2)
      );
      object-fit: contain;

      @include breakpoint(md) {
        max-width: rem-calc(300);
      }
    }
  }

  @include breakpoint(sm) {
    padding-right: rem-calc(50);
    margin-bottom: 0;
  }

  @include breakpoint(lg) {
    padding-right: 0;
  }
}

@mixin padTouchTarget($padding: 14px) {
  // Fix Lighthouse failure for `tap-targets`
  display: block;
  padding-top: $padding;
  padding-bottom: $padding;

  @include breakpoint(md) {
    padding: 0;
  }
}

.bottomWrapper {
  font-size: rem-calc(14);
  text-align: center;

  @include breakpoint(sm) {
    flex-basis: 100%;
  }

  @include breakpoint(lg) {
    flex-basis: auto;
  }
}

.bottomLinkGrid {
  justify-content: center;
  display: flex;
  flex-direction: column;

  li {
    background-color: var(--pbsk-color-pbsblue);
    white-space: nowrap;
    padding: 0 0.5em;

    /* stylelint-disable no-descending-specificity */
    a, span button {
      display: block;
      text-decoration: underline;
      line-height: 1;

      @include padTouchTarget;
    }
  }

  @include breakpoint(sm) {
    flex-direction: row;
  }
}

.copyright {
  display: block;
  font-family: var(--pbsk-font-family-sans);

  @include breakpoint(md) {
    margin-top: rem-calc(12);
  }
}
