@import '@/styles/utils/mixins/all';

// Base PBS KIDS styles that serve to augment `modern-normalize.css`.
// Let's try to keep this file as small as possible.
html {
  line-height: 1.5;
}

body {
  --pbsk-client-scrollbar-width: 0px;
  color: var(--pbsk-theme-bodyTextColor);
}

svg {
  display: block;
  width: 100%;
  height: 100%;
}

menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img, video {
  max-width: 100%;
  height: auto;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

[type='button'],
[type='reset'],
[type='submit'],
button,
input,
optgroup,
select,
textarea {
  border: 0 solid;
  line-height: inherit;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  appearance: button;
  background-color: transparent;
  background-image: none;
  color: inherit;
  cursor: pointer;
}

:disabled {
  cursor: default;
}

a, button,
[type='button'],
[type='text'],
[type='reset'],
[type='range'],
[type='submit'],
[tabindex='0'] {
  @include focus-visible {
    outline: 3px solid var(--pbsk-theme-uiHighlightColor);
    outline-offset: 2px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
  font-family: var(--pbsk-font-family-headline);
}

h5,
h6 {
  font-family: var(--pbsk-font-family-sans-bold);
}

a {
  color: inherit;
  text-decoration: inherit;
}
