@import '@/styles/utils/functions/rem-calc';
@import '@/styles/utils/mixins/breakpoints';

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  background-color: var(--pbsk-color-white);
  max-width: 700px;
  color: var(--pbsk-color-black);

  @include breakpoint(sm) {
    flex-direction: row-reverse;
    gap: 1rem;
  }

  @include breakpoint(md) {
    min-width: 700px;
  }
}

.imageLink {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex: 1 0 60%;
  }
}

.contentPanel {
  padding-top: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-family: var(--pbsk-font-family-sans-bold);
    font-size: rem-calc(18);
    word-break: break-word;
  }

  p {
    font-size: rem-calc(16);
    margin-bottom: 2rem;
  }

  @include breakpoint(sm) {
    padding-top: 2rem;

    h3, p {
      font-size: rem-calc(12);
    }
  }

  @include breakpoint(lg) {
    h3 {
      font-size: rem-calc(18);
    }

    p {
      font-size: rem-calc(16);
    }
  }
}

.linkButton {
  color: var(--pbsk-color-white);
  background-color: var(--pbsk-color-black);
  padding: .75rem 1rem;
  border-radius: .75rem;
  font-family: var(--pbsk-font-family-sans-bold);
  font-size: rem-calc(14);
  text-transform: uppercase;

  @include breakpoint(md) {
    font-size: rem-calc(18);
  }
}
