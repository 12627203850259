.button {
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;

    // Safari < 14.5 does not support the `inset` property :'(
    // https://caniuse.com/mdn-css_properties_inset
    top: 6px;
    right: 6px;
    bottom: 6px;
    left: 6px;
    width: 36px;
    height: 36px;
    background-color: var(--pbsk-color-red);
    z-index: -1;
    border-radius: 50%;
  }
}

.icon {
  width: 28px;
  height: 28px;
  fill: #fff;
}
