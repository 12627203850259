@import '@/styles/variables/breakpoints';
@import '@/styles/utils/mixins/breakpoints';

:root {
  --pbsk-mq-xs: #{$breakpoint-xs};
  --pbsk-mq-sm: #{$breakpoint-sm};
  --pbsk-mq-md: #{$breakpoint-md};
  --pbsk-mq-lg: #{$breakpoint-lg};
  --pbsk-mq-xl: #{$breakpoint-xl};
  --pbsk-mq-xxl: #{$breakpoint-xxl};

  --pbsk-max-screen-width: #{$max-screen-width};
  --pbsk-max-lower-modules-width: #{$max-lower-modules-width};
  --pbsk-max-lower-game-modules-width: #{$max-lower-game-modules-width};
  --pbsk-max-text-module-width: #{$max-text-module-width};

  --pbsk-gutter: 16px;
  --pbsk-margin: 20px;
  --pbsk-grid-spacer: 20px;

  @include breakpoint(md) {
    --pbsk-gutter: 24px;
    --pbsk-margin: 32px;
    --pbsk-grid-spacer: 32px;
  }

  @include breakpoint(xl) {
    --pbsk-gutter: 40px;
    --pbsk-margin: 60px;
  }
}
