@mixin text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

@mixin text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

@mixin text-md {
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@mixin text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@mixin titleBar {
  font-family: var(--pbsk-font-family-sans-bold);

  @include text-lg;
  color: var(--pbsk-color-white);
  padding: var(--pbsk-spacing-2);
  background-color: var(--pbsk-color-pbsblue);
  border-bottom: 4px solid var(--pbsk-color-magenta);
}
