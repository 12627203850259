@import '@/styles/utils/mixins/breakpoints';

.button {
  padding: 0;
  line-height: 0.75;

  & > * {
    pointer-events: none;
  }
}

.modalOverride {
  width: 80%;

  @include breakpoint(md) {
    width: unset;
  }
}

.closeButtonOverride {
  button:first-of-type {
    position: absolute;
    top: unset;
    bottom: 20px;
    left: 20px;
    width: 55px;
    height: 55px;

    &::after {
      display: none;
    }
  }
}
