@import '@/styles/variables/breakpoints';
@import '@/styles/variables/spacing';
@import '@/styles/variables/grid';
@import '@/styles/variables/colors';

// Base component-specific CSS variables which set a default value for the component that can be overridden in different contexts.
@import '@/components/base/RangeSlider/variables';

:root {
  --pbsk-focus-outline-width: 10px;
  --pbsk-component-media-item-hover-border-color: var(--pbsk-theme-uiHighlightColor);
}
