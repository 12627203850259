@import '@/styles/utils/mixins/dev-ui';

.pageInfoTab {
  @include devToolTab;
  z-index: 99999999;
  top: calc(var(--pbsk-dev-ui-default-padding) * 2);
  right: 0;
  box-sizing: border-box;
  border-radius: var(--pbsk-dev-ui-border-radius) 0 0 var(--pbsk-dev-ui-border-radius);

  .title {
    font-size: 1em;
    font-family: var(--pbsk-font-family-sans-bold);
    cursor: pointer;
  }

  .closeButton {
    position: absolute;
    right: 0;
    top: 0;

    &::after {
      background-color: var(--pbsk-theme-uiLabelColor);
    }

    path {
      fill: var(--pbsk-theme-uiControlColor);
    }
  }

  &.open {
    width: 320px;

    .title {
      pointer-events: none;
    }
  }

  .body {
    dt {
      margin: 0 0.5em 0.5em;
      font-family: var(--pbsk-font-family-sans-bold);
    }

    dd {
      margin: 0 1.5em 0.25em;
    }

    hr {
      margin-top: 1em;
      border-width: 0 0 1px;
      border-color: var(--pbsk-theme-uiLabelColor);
    }
  }
}

