@import '@/styles/utils/mixins/breakpoints';

.rootWrapper {
  position: relative;
}

.mainLayout {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  @supports (overflow-wrap: anywhere) {
    overflow-wrap: anywhere;
  }

  @include breakpoint(xs) {
    overflow-wrap: normal;
  }
}

@mixin floatingLayer($zIndex: 0, $height: 100%) {
  position: absolute;

  // Safari < 14.5 does not support the `inset` property :'(
  // https://caniuse.com/mdn-css_properties_inset
  top: 0;
  right: 0;
  left: 0;
  height: $height;
  z-index: $zIndex;
}

.pageBackgroundLayer {
  @include floatingLayer(-1);

  .solidColor {
    @include floatingLayer(1, 100%);
    background-color: var(--pbsk-theme-bodyBackgroundColor);
  }

  [data-has-masthead-theme='false'] & {
    --background-height: 400px;

    @include breakpoint(lg) {
      --background-height: 466px;
    }

    // Page theme gradients and background images should not be used when masthead content is present
    .image {
      @include floatingLayer(2, var(--background-height));

      background-repeat: no-repeat;
      background-position: top center;
      background-image: var(--pbsk-theme-backgroundImage);
      background-size: auto var(--background-height);
    }

    .gradientOverlay {
      @include floatingLayer(3, var(--background-height));

      background: linear-gradient(
        to bottom,
        var(--pbsk-theme-accentBackgroundColor, transparent)
        30%,
        var(--pbsk-theme-bodyBackgroundColor)
        90%
      );
    }
  }
}
