@import '@/styles/utils/mixins/breakpoints';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgb(0 0 0 / 60%);
  display: block;
}

.modalBody {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  opacity: 0;
}

.useAnimation {
  transition: opacity 250ms linear;
}

.showModal {
  opacity: 1;
}

.closeBtn {
  position: absolute;
  right: var(--pbsk-spacing-1);
  top: var(--pbsk-spacing-1);
  z-index: 1;
}
