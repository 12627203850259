@mixin animation($animationName, $duration: 300ms, $timing: ease-in-out) {
  animation-name: $animationName;
  animation-duration: $duration;
  animation-timing-function: $timing;
  transition: none;
}

@mixin preventCancelAnimation {
  pointer-events: none;
}

@mixin standardTransition($properties: all, $duration: 300ms) {
  transition-property: $properties;
  transition-duration: $duration;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}
