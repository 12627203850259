:root {
  --pbsk-component-range-slider-height: 12px;
  --pbsk-component-range-slider-knob-height: 20px;
  --pbsk-component-range-slider-knob-width: 20px;
  --pbsk-component-range-slider-knob-size--hover: 110%;
  --pbsk-component-range-slider-knob-border-radius: 50%;
  --pbsk-component-range-slider-opacity--disabled: .7;
  --pbsk-component-range-slider-knob-color: #ccc;
  --pbsk-component-range-slider-knob-color--hover: #aaa;
  --pbsk-component-range-slider-background-color: #e6e6e6;
  --pbsk-component-range-slider-background-color-rgb: 230 230 230;
  --pbsk-component-range-slider-progress-background-color: var(--pbsk-color-logo-green);
  --pbsk-component-range-slider-progress-background-color--disabled: var(--pbsk-color-medium-gray);
}
