@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  font-size: 1rem; // Prevents WAVE 'small text' errors even though text is not visible.
}

// Use this mixin if you need to 'undo' the visually hidden styles of 'sr-only'.
// For example if you want to 'unhide' a hidden element when it receives focus,
// or at a certain breakpoint, or in a print stylesheet.
@mixin sr-only-undo {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
