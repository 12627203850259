@import '@/styles/utils/functions/rem-calc';
@import '@/styles/utils/mixins/breakpoints';

.stationPickerWrapper {
  padding: var(--pbsk-spacing-6);
  background-color: #fff;
  position: relative;
  width: 320px;
  font-family: var(--pbsk-font-family-sans-bold);
  font-size: rem-calc(14);

  @include breakpoint(md) {
    width: 400px;
  }

  @include breakpoint(lg) {
    width: 500px;
  }

  .stationLogo {
    width: max-content;

    img {
      // Specify this to prevent the inheritance of the value from parent styles
      vertical-align: baseline;
    }
  }
}

.initialContainer {
  display: flex;
  flex-direction: row;
}

.zipCodeInput {
  color: var(--pbsk-color-medium-gray);
  height: 2.25em;
  margin: var(--pbsk-spacing-5);
  border: 2px solid black;
  text-align: center;
}

.zipCodeContainer {
  margin: var(--pbsk-spacing-2);
}

.zipCodeContainer button {
  display: inline;
  align-items: center;
}

.useZipCode {
  color: var(--pbsk-color-dark-gray);
  font-size: rem-calc(9);

  @include breakpoint(md) {
    font-size: rem-calc(12);
  }

  @include breakpoint(lg) {
    font-size: rem-calc(14);
  }
}

.currentStationLogo {
  height: 78px;
  width: 78px;
  border: 1px solid black;
  margin-right: var(--pbsk-spacing-4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--pbsk-spacing-2);
}

.currentStationName {
  color: black;
  margin-top: 0;
  margin-bottom: var(--pbsk-spacing-2);
  text-align: start;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.stationName {
  width: calc(100% - (var(--station-logo-size) + 3px + var(--pbsk-spacing-3)));
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.currentStation {
  color: var(--pbsk-color-dark-gray);
  margin-bottom: var(--pbsk-spacing-3);
  text-align: start;
}

.selectStationContainer {
  display: flex;
  flex-direction: column;
}

.selectStation {
  color: var(--pbsk-color-dark-gray);
  text-align: center;
  margin-bottom: var(--pbsk-spacing-3);
}

.results {
  color: var(--pbsk-color-dark-gray);
  font-size: rem-calc(12);
  text-align: center;
  font-weight: 400;
  margin-top: var(--pbsk-spacing-4);
  margin-bottom: var(--pbsk-spacing-4);

  button {
    text-decoration: underline;
    color: var(--pbsk-color-pbsblue);
  }
}

.selectStationContainer ul {
  margin-bottom: var(--pbsk-spacing-1);
}

.selectStationContainer li {
  list-style-type: none;
  background: var(--pbsk-color-lightest-gray) !important;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: var(--pbsk-spacing-1);
  color: var(--pbsk-color-medium-gray);
  padding: 0 0.5em;

  button {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
  }
}

.stationSquare {
  margin: var(--pbsk-spacing-1) var(--pbsk-spacing-2) var(--pbsk-spacing-1) var(--pbsk-spacing-1);
  padding: var(--pbsk-spacing-2);
  max-height: 40px;
  max-width: 40px;
  background-color: #fff;
}

.changeStationBtn {
  background: var(--pbsk-color-pbsblue);
  border: 0;
  color: #fff;
  cursor: pointer;
  height: 2.5em;
  padding: 0 var(--pbsk-spacing-3);
  text-transform: uppercase;
  display: block;
}

.stationPickerWrapper.hasBubbleArrowStyle::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 10%;
  width: 0;
  height: 0;
  border-top: solid 20px #fff;
  border-left: solid 20px transparent;
  border-right: solid 20px transparent;
  display: none;
}

@include breakpoint('(min-width: #{$breakpoint-sm}) and (min-height: 450px)') {
  .stationPickerWrapper.hasBubbleArrowStyle::after {
    top: 100%;
    left: 10%;

    // Prevent gap from appearing at some screen widths
    transform: translateY(-1px);
    display: block;
  }

  .modalOverride {
    position: absolute;
    left: 3em;
    top: auto;
    transform: unset;
    bottom: 3.5em;
  }

  .overlayOverride {
    display: none;
  }
}
