@import '@/styles/utils/functions/rem-calc';
@import '@/styles/utils/mixins/breakpoints';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: rem-calc(390);
  height: rem-calc(222);
  max-width: 95vw;
  overflow: hidden;
  border: 10px solid var(--pbsk-color-shade-magenta);
  background-color: var(--pbsk-color-white);
  text-align: center;
}

.title {
  margin: 1rem 1.5rem 0;
  font-family: var(--pbsk-font-family-headline);
  color: var(--pbsk-color-magenta);
  font-size: rem-calc(24);
  line-height: 1;
  white-space: normal;

  @include breakpoint(xs) {
    font-size: rem-calc(30);
  }
}

.titleAllCaps {
  text-transform: uppercase;
}

.textLink {
  width: 100%;
  margin: 0;
  padding: 0 2rem;
  font-family: var(--pbsk-font-family-sans);
  font-size: rem-calc(14);
  font-weight: 400;
  text-decoration: underline;
  color: var(--pbsk-color-pbsblue);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.imageContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 140px;
  padding: 6px 10px;
  background-color: var(--pbsk-color-white);

  img {
    max-height: 48px;
  }

  @include breakpoint(xs) {
    width: 190px;

    &::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 4rem;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--pbsk-color-white);
    }

    &::after {
      content: '';
      position: absolute;
      right: -1rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 30px 0 30px 1rem;
      border-color: transparent transparent transparent var(--pbsk-color-white);
    }
  }
}

.backArrow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: unset;
  bottom: 20px;
  left: 20px;
  width: 55px;
  height: 55px;
  background-color: var(--pbsk-color-shade-blue);
  border-radius: 0;

  svg {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    fill: #fff;
  }
}
