/* stylelint-disable */
/* WARNING: Never edit this file directly! Update the file pbskids.colorpresets.config.js in the project root and run the update script mentioned in the comment there. */

:root {
  --pbsk-color-white: #fff;
  --pbsk-color-black: #000;
  --pbsk-color-focus-blue: #38D0FF;
  --pbsk-color-yellow: #F7E03B;
  --pbsk-color-sunshine: #F5B338;
  --pbsk-color-magenta: #E5296B;
  --pbsk-color-red: #FB4241;
  --pbsk-color-purple: #A220DF;
  --pbsk-color-pbsblue: #2638C4;
  --pbsk-color-blue: #2FC0EB;
  --pbsk-color-aqua: #2AE8D1;
  --pbsk-color-green: #00AB4E;
  --pbsk-color-darkgreen: #1A8549;
  --pbsk-color-logo-green: #99CF16;
  --pbsk-color-shade-yellow: #F5B338;
  --pbsk-color-shade-sunshine: #ED712C;
  --pbsk-color-shade-magenta: #BC005A;
  --pbsk-color-shade-red: #CE0201;
  --pbsk-color-shade-purple: #6A1B9A;
  --pbsk-color-shade-pbsblue: #0F1E8C;
  --pbsk-color-shade-blue: #0081CA;
  --pbsk-color-shade-aqua: #00A6A5;
  --pbsk-color-shade-green: #1A8549;
  --pbsk-color-shade-darkgreen: #0B6319;
  --pbsk-color-errorred: #D6383A;
  --pbsk-color-dark-gray: #353535;
  --pbsk-color-medium-gray: #595959;
  --pbsk-color-light-gray: #C4C4C4;
  --pbsk-color-lightest-gray: #FAFAFA;
  --pbsk-color-white-rgb: 255 255 255;
  --pbsk-color-black-rgb: 0 0 0;
  --pbsk-color-focus-blue-rgb: 56 208 255;
  --pbsk-color-yellow-rgb: 247 224 59;
  --pbsk-color-sunshine-rgb: 245 179 56;
  --pbsk-color-magenta-rgb: 229 41 107;
  --pbsk-color-red-rgb: 251 66 65;
  --pbsk-color-purple-rgb: 162 32 223;
  --pbsk-color-pbsblue-rgb: 38 56 196;
  --pbsk-color-blue-rgb: 47 192 235;
  --pbsk-color-aqua-rgb: 42 232 209;
  --pbsk-color-green-rgb: 0 171 78;
  --pbsk-color-darkgreen-rgb: 26 133 73;
  --pbsk-color-logo-green-rgb: 153 207 22;
  --pbsk-color-shade-yellow-rgb: 245 179 56;
  --pbsk-color-shade-sunshine-rgb: 237 113 44;
  --pbsk-color-shade-magenta-rgb: 188 0 90;
  --pbsk-color-shade-red-rgb: 206 2 1;
  --pbsk-color-shade-purple-rgb: 106 27 154;
  --pbsk-color-shade-pbsblue-rgb: 15 30 140;
  --pbsk-color-shade-blue-rgb: 0 129 202;
  --pbsk-color-shade-aqua-rgb: 0 166 165;
  --pbsk-color-shade-green-rgb: 26 133 73;
  --pbsk-color-shade-darkgreen-rgb: 11 99 25;
  --pbsk-color-errorred-rgb: 214 56 58;
  --pbsk-color-dark-gray-rgb: 53 53 53;
  --pbsk-color-medium-gray-rgb: 89 89 89;
  --pbsk-color-light-gray-rgb: 196 196 196;
  --pbsk-color-lightest-gray-rgb: 250 250 250;
}
/* stylelint-enable */
