@mixin devToolBase() {
  --pbsk-dev-ui-border-radius: 8px;
  --pbsk-dev-ui-default-padding: 8px;

  background: var(--pbsk-theme-uiControlColor);
  color: var(--pbsk-theme-uiLabelColor);

  pre {
    padding: var(--pbsk-dev-ui-default-padding);
    background: rgb(0 0 0 / 20%);
    font-size: 10px;
    line-height: 1.2;
  }
}

@mixin devToolTab() {
  @include devToolBase;
  position: fixed;
  z-index: 9999999;
  box-shadow: 0 0 4px rgb(var(--pbsk-theme-uiLabelColor-rgb) / 45%);
  padding: var(--pbsk-dev-ui-default-padding);
}
