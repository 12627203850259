// Let's try to keep this file as small as possible.

// video.js
.vjs-error-display,
.vjs-control-bar {
  display: none;
}

// Controls are hidden but these still cause "small text" WAVE errors.
.vjs-control-bar,
.vjs-control-text {
  font-size: 1rem;
}

.vjs-text-track-display {
  // After updating to video.js version 8, captions sometimes floated outside the video panel.
  margin: 5vw !important;

  // Sometimes on video.js version 8, particularly on mobile, an `inset-block` value gets dynamically set
  // for this element and it causes non-DRM captions to be WAY too large. Not sure what that value is for,
  // but I'm not seeing any downside to zeroing it out.
  inset-block: 0 !important;

  // Some captions do a weird thing on livestream on video.js 8, where they
  // float outside the video panel and cause overflow issues.
  .vjs-live & {
    overflow: hidden;
  }
}

// videojs-ima.css
.video-js .ima-ad-container {
  /* Prevents the ad and control clicks. */
  pointer-events: none;

  /* Prevents the ad container from setting itself to z-index: 1111
  which is above our video player controls overlay. */
  z-index: 0 !important;
  overflow: hidden;

  div {
    iframe {
      width: 150%;
    }
  }
}

// nprogress - package we're using for the loader bar at the top of the page
#nprogress {
  position: fixed;
  z-index: 9999;

  --bar-color: var(--pbsk-theme-calloutBackgroundColor);

  .bar {
    width: 101vw !important; // not sure why this is needed, but without it, the bar doesn't extend all the way across the screen
    height: 6px !important;
    background: var(--bar-color) !important;
  }

  .peg {
    z-index: 9999;
    box-shadow: 0 0 10px var(--bar-color),
      0 0 5px var(--bar-color) !important;
  }
}
