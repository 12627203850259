// Let's try to keep this file as small as possible.
@import '@/styles/utils/mixins/breakpoints';

// Basic Visibility
.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

// Typography
.font-headline {
  font-family: var(--pbsk-font-family-headline);
}

.font-sans {
  font-family: var(--pbsk-font-family-sans);
}

.font-sans-bold {
  font-family: var(--pbsk-font-family-sans-bold);
}

// Videos/Games -- In order to add a polyfill for `aspect-ratio` in Safari <= 14, add a global class, using our mixin.
.aspect-game,
.aspect-video {
  @include aspect-ratio(16, 9);
}

// Screen Reader Only (visually hidden)
.sr-only {
  @include sr-only;
}

// Screen Reader Only (visually hidden), but will un-hide when the element receives focus.
.sr-only-focusable {
  @include sr-only;

  &:focus {
    @include sr-only-undo;
  }
}

// Utility Classes for Standardizing Main Page Layouts
.contain {
  width: 100%;
  max-width: var(--pbsk-max-screen-width);
  margin: 0 auto;
  padding: 0 var(--pbsk-margin);
}

.contain-full {
  width: 100%;
  max-width: var(--pbsk-max-screen-width);
  margin: 0 auto;
}

// Utility class for hiding cursor - use only if you have a valid reason to do so.
.cursor-none {
  cursor: none;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.loader-bug {
  background-image: var(--pbsk-loader-image-url, url('/puma/images/LogoBuffer.svg'));
  background-repeat: no-repeat;
  background-position: center;
  background-size: var(--pbsk-loader-image-size, contain);
  filter: drop-shadow(0 0 2px rgb(0 0 0 / 20%));
}

[data-use-loading-dots] {
  height: 100%;
  background-color: var(--pbsk-theme-bodyBackgroundColor);
  background-image: linear-gradient(
    transparent,
    rgb(0 0 0 / 20%),
  );

  img {
    --dot-width: 80px;
    width: 100%;
    height: 100%;
    background-image: url('/puma/images/LoadingDots.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: var(--dot-width) auto;
  }
}
