@import '@/styles/utils/mixins/dev-ui';
@import '@/styles/utils/mixins/typography';
@import '@/styles/utils/mixins/shadows';

.component {
  @include devToolTab;
  bottom: 0;
  left: 0;
  max-width: 100vw;
  border-top-right-radius: var(--pbsk-dev-ui-border-radius);

  @include text-sm;

  &[data-collapsed='true'] {
    cursor: pointer;
  }

  p {
    margin: 12px 0;
  }

  pre {
    max-height: 60vh;
    overflow-y: auto;
  }
}

.preClasses {
  max-width: 600px;

  @include text-xs;
}

.errorText {
  color: var(--pbsk-color-errorred);
}

.extraSmallText {
  @include text-xs;
}

.smallText {
  @include text-sm;
}

.largeText {
  @include text-lg;
}

.closeButtonClasses {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--pbsk-spacing-2);

  @include text-xs;
}
