.pbsk-animated {
  animation-duration: 250ms;
  animation-fill-mode: both;
}

// Fade In/Out
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.pbsk-anim-fade-in {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.pbsk-anim-fade-out {
  animation-name: fadeOut;
}

// Squish
@mixin squish($origin: center) {
  display: block;
  transform: scale3d(1,1,1) translateX(0);
  transform-origin: $origin;
  transition: transform .15s ease-in-out;

  &--down {
    transform: scale3d(.78, .85, 1) translateX(0);
    transition: transform .1s ease-in-out;
  }

  &--up {
    @include preventCancelAnimation;
    transform: scale3d(1.2, 1.15, 1) translateX(0);
    transition: transform .2s ease-in-out;
  }
}

.squish {
  @media (prefers-reduced-motion: no-preference) {
    @include squish;
  }
}

.squishLeft {
  @media (prefers-reduced-motion: no-preference) {
    @include squish(left);

    &--down {
      transform: scale3d(.85, .9, 1) translateX(-.375rem);
    }

    &--up {
      transform: scale3d(1.15, 1.05, 1) translateX(.375rem);
    }
  }
}

.squishRight {
  @media (prefers-reduced-motion: no-preference) {
    @include squish(right);

    $x-squish: 85%;

    &--down {
      transform: scale3d(.85, .9, 1) translateX(.25rem);
    }

    &--up {
      transform: scale3d(1.15, 1.05, 1) translateX(-.25rem);
    }
  }
}

// Jelly/Jelly Squish
@keyframes jelly {
  0% {
    transform: scale3d(.83, .9, 1);
  }

  16% {
    transform: scale3d(1.1, .85, 1);
  }

  33% {
    transform: scale3d(1.2, .8, 1);
  }

  66% {
    transform: scale3d(.9, 1.2, 1);
  }

  83% {
    transform: scale3d(.95, 1.1, 1);
  }

  100% {
    transform: scale3d(1,1,1);
  }
}

@keyframes jellySquish {
  0% {
    transform: scale3d(1, 1, 1);
  }

  10% {
    transform: scale3d(.87, .94, 1);
  }

  26% {
    transform: scale3d(1.06, .91, 1);
  }

  43% {
    transform: scale3d(1.1, .85, 1);
  }

  76% {
    transform: scale3d(.94, 1.12, 1);
  }

  93% {
    transform: scale3d(.95, 1.06, 1);
  }
}

.jellySquish {
  @media (prefers-reduced-motion: no-preference) {
    @include animation($animationName: jellySquish, $duration: .6s);
  }
}

.jellySquishClick {
  @media (prefers-reduced-motion: no-preference) {
    transform: scale3d(1,1,1);
    transition: transform .15s ease-in-out;

    &--down {
      transform: scale3d(.83, .9, 1);
      transition: transform .1s ease-in-out;
    }

    &--up {
      @include preventCancelAnimation;
      @include animation($animationName: jelly, $duration: .5s);
    }
  }
}

// Marshmallow Pull
@mixin pull {
  display: block;
  transform: scale3d(1,1,1);
  transition: transform .15s ease-in-out;

  &--down {
    transform: scale3d(1.15, .95, 1);
    transition: transform .1s ease-in-out;
  }

  &--up {
    @include preventCancelAnimation;
    transform: scale3d(.85, 1.15, 1);
    transition: transform .15s ease-in-out;
  }
}

.pullLeft {
  @media (prefers-reduced-motion: no-preference) {
    @include pull;
    transform-origin: right;
  }
}

.pullRight {
  @media (prefers-reduced-motion: no-preference) {
    @include pull;
    transform-origin: left;
  }
}

// Arrow Zooms
@mixin zoom($animationName) {
  display: block;
  overflow: hidden;
  transform: scale3d(1, 1, 1);
  transition: transform .15s linear;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &--down {
    transform: scale3d(.9, 1.025, 1);
    transition: transform .1s linear;

    svg {
      transform: translate3d(-50%, -50%, 0) scale3d(0.8, 1.1, 1);
      transition: transform .075s linear;
    }
  }

  &--up {
    @include preventCancelAnimation;
    transform: scale3d(1.1,.95,1);
    transition: transform .2s linear;

    svg {
      transform: translate3d(-50%, -50%, 0) scale3d(0.8, 1.1, 1);
      animation: $animationName .3s ease-out, peekaboo .3s steps(3, end);
      transition: none;
    }
  }
}

.zoomLeft {
  @media (prefers-reduced-motion: no-preference) {
    @include zoom(zoomLeft);
    transform-origin: right;
  }
}

.zoomRight {
  @media (prefers-reduced-motion: no-preference) {
    @include zoom(zoomRight);
    transform-origin: left;
  }
}

@keyframes zoomLeft {
  0% {
    transform: translate3d(-50%, -50%, 0) scale3d(0.8, 1.1, 1);
  }

  33% {
    transform: translate3d(-180%, -50%, 0) scale3d(1.75, .25, 1);
  }

  34% {
    transform: translate3d(150%, -50%, 0) scale3d(1.75, .55, 1);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  }
}

@keyframes zoomRight {
  0% {
    transform: translate3d(-50%, -50%, 0) scale3d(0.8, 1.1, 1);
  }

  43% {
    transform: translate3d(180%, -50%, 0) scale3d(1.75, .25, 1);
  }

  44% {
    transform: translate3d(-150%, -50%, 0) scale3d(1.75, .55, 1);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  }
}

@keyframes peekaboo {
  0% {
    opacity: 1;
  }

  31% {
    opacity: 1;
  }

  32% {
    opacity: 0;
  }

  35% {
    opacity: 0;
  }

  36% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

// Crank
.crank {
  @media (prefers-reduced-motion: no-preference) {
    &--down {
      transform: rotate(0);
    }

    &--up {
      @include preventCancelAnimation;
      animation: crank .6s ease-in-out;
    }
  }
}

@keyframes crank {
  0% {
    transform: rotate(0);
  }

  8% {
    transform: rotate(-5deg);
  }

  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(15deg);
  }

  60% {
    transform: rotate(30deg);
  }

  80% {
    transform: rotate(30deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

// Spin
.spin {
  @media (prefers-reduced-motion: no-preference) {
    transform: rotate(0);
    transition: transform .075s ease-in-out;
    transform-origin: center;

    &--down {
      transform: rotate(-15deg);
    }

    &--up {
      @include preventCancelAnimation;
      animation: spin .8s cubic-bezier(.24,.92,.94,1);
      transition: none;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(-15deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
